import { notify } from "../Components/Common";
import ErrorPage from "../Components/ErrorPage";
// main API URL
const backend = process.env.REACT_APP_URL;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const JS_ORIGIN = process.env.REACT_APP_JS_ORIGIN;
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
export const GTM_TRACKING_ID = process.env.REACT_APP_GTM_TRACKING_ID;
export const CalendlyURL = "https://calendly.com/pinaippu/productdemo";
export const ZOHO_CLIENT_ID = process.env.REACT_APP_ZOHO_CLIENT_ID;
export const REAL_URL = process.env.REACT_APP_REAL_URL;
export const SENTRY_DSN = process.env.REACT_SENTRY_DSN;

// header

export function getHeaders(data) {
  const accessToken = JSON.parse(
    localStorage.getItem("userTokens")
  )?.access_token;

  var myHeaders = new Headers();

  if (accessToken) {
    if (data instanceof FormData) {
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      // myHeaders.append("Content-Type", "multipart/form-data");
      return myHeaders;
    } else {
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    }
  }
}

export function apiFetch(method, path, data, auth) {
  const url = backend + path;
  // const headers = auth
  //   ? getHeaders(data)
  //   : data instanceof FormData
  //   ? { "Content-Type": "multipart/form-data" }
  //   : { "Content-Type": "application/json" };
  const headers = auth
    ? getHeaders(data)
    : {
        "Content-Type":
          data instanceof FormData ? "multipart/form-data" : "application/json",
      };
  const options = {
    method,
    headers,
    ...(data
      ? data instanceof FormData
        ? { body: data }
        : { body: JSON.stringify(data) }
      : {}),
  };

  return fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return response;
      } else if (response.status === 401) {
        window.location.href = "/login";
        localStorage.removeItem("userTokens");
      } else if (response.status === 400) {
        response?.text().then((text) => {
          const tempErrors = JSON.parse(text);
          // setErrors(JSON.parse(text));
          Object.values(tempErrors).map((errorList) =>
            errorList.map((error) => {
              notify("error", error);
            })
          );
        });
        return response;
      } else if (response.status === 403) {
        response?.text().then((text) => {
          const tempErrors = JSON.parse(text);
          notify("error", tempErrors.detail);
          console.log("tempErrors", tempErrors.detail);
          if (tempErrors.detail === "User is not authenticated") {
            localStorage.removeItem("userTokens");
            window.location.href = "/login";
          } else {
            window.location.href = "/error";
          }
        });
      } else if (response.status === 500) {
        return response;
      }
    })
    .catch((error) => {
      console.error(`Failed to fetch ${url}`, error);
      throw error;
    });
}

export function logOut() {
  const url = backend + "dj-rest-auth/logout/";
  const headers = getHeaders();
  const options = {
    method: "POST",
    headers,
  };
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Failed to log out: ${response.status}`);
      }
      return response;
    })
    .catch((error) => {
      console.error(`Failed to log out at ${url}`, error);
      throw error;
    });
}

export const getBrandDetails = (param) => {
  const url = `branding-details/?domain=${param}`;
  return apiFetch("GET", url, null, true)
    .then((res) => {
      if (res.ok) {
        console.log("res", res);
        return res.json();
      } else {
        throw new Error("Failed to Get brand details");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
